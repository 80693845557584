import { generateSortFunction } from '../utils';

export const mutations = {
    setBusinessEntity: (state, businessEntity) => {
      // Fill in missing values
      businessEntity.business_entity_data.authorized_signatory = businessEntity.business_entity_data.authorized_signatory || {};
      businessEntity.business_entity_data.contact = businessEntity.business_entity_data.contact || {};
      businessEntity.business_entity_data.sop_contact = businessEntity.business_entity_data.sop_contact || {};
      state.businessEntity = businessEntity;
    },
    setBusinessEntities: (state, businessEntity) => {
      state.businessEntities = businessEntity;
    },
    setBusinessEntityTasks: (state, businessEntityTasks) => {
      state.businessEntityTasks = businessEntityTasks;
    },
    setCompanyApplicants: (state, companyApplicants) => {
      state.companyApplicants = companyApplicants;
    },
    setBeneficialOwners: (state, beneficialOwners) => {
      state.beneficialOwners = beneficialOwners;
    },
    setDirectors: (state, directors) => {
      state.directors = directors;
    },
    setSopContact: (state, sopContact) => {
      state.sopContact = sopContact;
    },
    setManagers: (state, managers) => {
      state.managers = managers;
    },
    setMembers: (state, members) => {
      state.members = members;
    },
    setOfficers: (state, officers) => {
      state.officers = officers;
    },
    setPrimaryContact: (state, primaryContact) => {
      state.primaryContact = primaryContact;
    },
    setResponsibleParty: (state, responsibleParty) => {
      state.responsibleParty = responsibleParty;
    },
    setBusinessEntityAddresses: (state, addresses) => {
      state.addresses = addresses;      
      //overwrite the business entity data address with the latest addresses model
      state.businessEntity.business_entity_data.address = addresses.filter(address => address.address_type === 'physical').map(a => a.address);
      state.businessEntity.business_entity_data.mailing_address = addresses.filter(address => address.address_type === 'mailing').map(a => a.address);
      state.businessEntity.business_entity_data.principal_address = addresses.filter(address => address.address_type === 'principal').map(a => a.address);


    },
    setBusinessEntityContacts: (state, contacts) => {
      state.contacts = contacts;

      //extract the contacts from Contact endpoint
      state.members = contacts.filter(contact => contact.contact_type === 'member').map(c => c.contact);
      state.managers = contacts.filter(contact => contact.contact_type === 'manager').map(c => c.contact);
      state.primaryContact = contacts.filter(contact => contact.contact_type === 'primary').map(c => c.contact);
      state.sopContact = contacts.filter(contact => contact.contact_type === 'sop_contact').map(c => c.contact);

      //overwrite the business entity data contact with the latest contact model
      state.businessEntity.business_entity_data.managers = state.managers;

      // Bridge the contacts model to the business entity data model for members
      const modifiedMembersToMatchBusinessEntityData = state.members.map((memberContact) => {
        // Titles are guarenteed to match the contact_type manager, so we check title because it is easier.
        const manager = memberContact.titles?.includes('manager') ?? false;
        const businessEntityDataMemberContact = {
          ...memberContact,
          manager
        };
        delete businessEntityDataMemberContact.care_of;
        delete businessEntityDataMemberContact.titles;
        delete businessEntityDataMemberContact.deleted;
        delete businessEntityDataMemberContact.deleted_on_sf;
        delete businessEntityDataMemberContact.external_sf_id;
        delete businessEntityDataMemberContact.created_datetime;
        delete businessEntityDataMemberContact.updated_datetime;

        return businessEntityDataMemberContact;
      });

      state.businessEntity.business_entity_data.members = modifiedMembersToMatchBusinessEntityData;
      state.businessEntity.business_entity_data.contact = state.primaryContact[0];
      state.businessEntity.business_entity_data.sop_contact = state.sopContact[0];
    },
    setRegisteredAgentContacts: (state, raContacts) => {
      state.registeredAgents = raContacts;
    },
    setBusinessEntityFKAs: (state, fkas) => {
      state.fkas = fkas;
    },
    setJurisdictions: (state, jurisdictions) => {
      // Sort jurisdictions by is-domestic DESC, jurisdiction code ASC
      const jurisdictionSort = generateSortFunction([
        { accessor: (j) => j.domestic_flag, ascending: false },
        { accessor: (j) => j.jurisdiction_code, ascending: true }
      ]);
      jurisdictions.sort(jurisdictionSort);

      // Add missing keys to extra state info
      for (const jurisdiction of jurisdictions) {
        if (jurisdiction.jurisdiction_code === 'CA' && !jurisdiction.extra_state_info.elective_ceo) {
          jurisdiction.extra_state_info.elective_ceo = {};
        }
      }

      state.jurisdictions = jurisdictions;
    },
    setEventConfigs: (state, eventConfigs) => {
      state.eventConfigs = eventConfigs;
    },
    setEventConfig: (state, eventConfigData) => {
      state.eventConfigs[eventConfigData.jurisdictionCode] = eventConfigData.data;
    },
    setDocuments: (state, documents) => {
      state.documents = documents;
    },
    setDocumentViewUrl: (state, documentViewUrl) => {
      state.documentViewUrl = documentViewUrl;
    },
    setEntityName: (state, entityName) => {
      state.businessEntity.business_entity_name = entityName;
    },
    setEntityType: (state, entityType) => {
      state.businessEntity.business_entity_type = entityType;
    },
    setHasNameReservation: (state, hasNameReservation) => {
      state.businessEntity.has_name_reservation = hasNameReservation;
      if(!hasNameReservation)
        state.businessEntity.name_reservation_number = "";
    },
    setNameReservationNumber: (state, nameReservationNumber) => {
      state.businessEntity.name_reservation_number = nameReservationNumber;
    },
    setEin: (state, ein) => {
      state.businessEntity.business_entity_data.ein = ein;
    },
    setBusinessPurpose: (state, businessPurpose) => {
      state.businessEntity.business_purpose = businessPurpose;
    },
    setBusinessIndustry: (state, businessIndustry) => {
      state.businessEntity.business_entity_data.business_industry = businessIndustry;
      state.businessEntity.business_industry = businessIndustry;
    },
    setBusinessNaicsCode: (state, naicsCode) => {
      state.businessEntity.naics_code = naicsCode;
    },
    setManagerType: (state, managerType) => {
      state.businessEntity.business_entity_data.manager_type = managerType;
      state.businessEntity.management_structure = managerType;
    },
    setAuthorizedSignatory: (state, signatory) => {
      // Save the current value of the authorized signatory title
      const authSigTitle = state.businessEntity.business_entity_data.authorized_signatory && state.businessEntity.business_entity_data.authorized_signatory.title
        ? state.businessEntity.business_entity_data.authorized_signatory.title
        : '';
      // Set the authorized signatory to a clone of the input
      state.businessEntity.business_entity_data.authorized_signatory = { ...signatory };
      // Add back the title
      state.businessEntity.business_entity_data.authorized_signatory.title = authSigTitle;
    },
    setBusinessEntityMembers: (state, members) => {
      if (members === null) {
        state.businessEntity.business_entity_data.members = [];
      } else {
        state.businessEntity.business_entity_data.members = members;
      }
    },
    setBusinessEntityManagers: (state, managers) => {
      if (managers === null) {
        state.businessEntity.business_entity_data.managers = [];
      } else {
        state.businessEntity.business_entity_data.managers = managers;
      }
    },
    setBusinessEntityOfficers: (state, officers) => {
      if (officers === null) {
        state.businessEntity.business_entity_data.officers = [];
      } else {
        state.businessEntity.business_entity_data.officers = officers;
      }
    },
    setBusinessEntityDirectors: (state, directors) => {
      if (directors === null) {
        state.businessEntity.business_entity_data.directors = [];
      } else {
        state.businessEntity.business_entity_data.directors = directors;
      }
    },
    setAuthorizedShares: (state, authorizedShares) => {
      state.businessEntity.business_entity_data.authorized_shares = authorizedShares;
    },
    setParValue: (state, parValue) => {
      state.businessEntity.par_value = parValue;
    },
    setHasMoreThanOneShare: (state, hasMoreThanOneShare) => {
      state.businessEntity.business_entity_data.has_more_than_one_share = hasMoreThanOneShare;
    },
    setFilingDate: (state, filingDate) => {
      state.jurisdictions[0].jurisdiction_filing_date = filingDate;
    },
    setFilingNumber: (state, filingNumber) => {
      state.jurisdictions[0].jurisdiction_filing_number = filingNumber;
    },
    setBusinessEntityContact: (state, contact) => {
      state.businessEntity.business_entity_data.contact = contact;
    },
    setBusinessEntitySopContact: (state, contact) => {
      state.businessEntity.business_entity_data.sop_contact = contact;
    },
    setBusinessEntityAddress: (state, address) => {
      state.businessEntity.business_entity_data.address = address;
    },
    setBusinessEntityMailingAddress: (state, mailingAddress) => {
      state.businessEntity.business_entity_data.mailing_address = mailingAddress;
    },
    setBusinessEntityPrincipalAddress: (state, principalAddress) => {
      state.businessEntity.business_entity_data.principal_address = principalAddress;
    },
    setBusinessEntityRegisteredAgent: (state, registeredAgent) => {
      state.businessEntity.business_entity_data.registered_agent = registeredAgent;
    },
    setBusinessEntityExternalRaCompanyId: (state, externalRaCompanyId) => {
      state.businessEntity.external_ra_company_id = externalRaCompanyId;
    },
    setBusinessEntityUpdatedBy: (state, updatedBy) => {
      state.businessEntity.updated_by = updatedBy;
    },
    setZenbusinessDNS: (state, zenbusinessDNS) => {
      state.website.zenbusiness_dns = zenbusinessDNS;
    },
    setCreditBalances: (state, creditBalances) => {
      state.creditBalances = creditBalances;
    },
    setEntityFilingChanges: (state, entityFilingChanges) => {
      state.entityFilingChanges = entityFilingChanges;
    },
    setContactTypes: (state, contactTypes) => {
      state.contactTypes = contactTypes;
    },
    setComplianceEvents: (state, complianceEvents) => {
      state.complianceEvents = complianceEvents;
    },
    setAuditRecords: (state, auditRecords) => {
      state.audit_records = auditRecords;
    },
    setRaTrackingRecords: (state, trackingRecords) => {
      let recordsByJurisdiction = {}
      trackingRecords.forEach(record => {
          const jurisdiction = record.jurisdiction_code
          if (Object.prototype.hasOwnProperty.call(recordsByJurisdiction, jurisdiction)) {
            recordsByJurisdiction[jurisdiction].push(record)
          } else {
            recordsByJurisdiction[jurisdiction] = [record]
          }
        }
      )
      let sortedRecords = {}
      Object.keys(recordsByJurisdiction).forEach((jurisdiction) => {
        let records = recordsByJurisdiction[jurisdiction].sort( (record1, record2) => {
          if (record1.created_datetime >= record2.created_datetime) {
            return -1
          }
          return 1
        })
        sortedRecords[jurisdiction] = records
      })
      state.raTrackingRecordsByJurisdiction = sortedRecords
    }
  };

export default {};
